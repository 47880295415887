import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PAGE_LIMIT } from '@/core/constants';
import { DEFAULT_NAME } from '@/core/constants/workstation';
import DefaultEmptyState from '@/modules/profile/components/DefaultEmptyState.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
import ProfileReviewCardComponent from './ProfileReviewCardComponent.vue';
export default defineComponent({
    name: 'profile-review',
    components: {
        ProfileReviewCardComponent,
        DefaultEmptyState
    },
    data() {
        return {
            hasReviews: false,
            /* eslint-disable global-require */
            thumbnail: `${require('@/assets/images/empty-review-image.png')}`,
            /* eslint-disable global-require */
            description: 'You don’t have any reviews yet. You can get reviews by completing jobs on iknowa.',
            ratings: [],
            ratingSummary: {
                avgRating: 0,
                totalRatings: 0,
                ratings: []
            },
            pageFilters: {
                page: 1,
                limit: PAGE_LIMIT
            },
            user: null,
            pageListMetadata: {
                page: 0,
                limit: PAGE_LIMIT,
                totalCount: 0,
                totalPages: 0,
            },
            hasLoaded: false
        };
    },
    computed: {
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
    },
    async created() {
        const { $route, activeUserWorkstation } = this;
        const { params } = $route;
        const { userId } = params;
        this.hasLoaded = false;
        // don't load reviews if not default
        // as reviews dont have the composition of ws
        // for viewer, show everything
        if (activeUserWorkstation?.name !== DEFAULT_NAME) {
            this.hasLoaded = !this.hasLoaded;
            return;
        }
        await this.getUserRatingSummary(userId)
            .then((ratingSummary) => {
            this.ratingSummary = ratingSummary;
        });
        await this.getUser(userId)
            .then(async (user) => {
            this.user = user;
        });
        await this.fetchUserRatingList();
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'getUser',
            'createUserRating',
            'getUserRatingsPageList',
            'getUserRatingSummary'
        ]),
        async fetchUserRatingList(filter = {}) {
            const { user } = this;
            const currentFilters = {
                ...this.pageFilters,
                ...filter,
                ...{
                    toUserId: user?.id
                }
            };
            const ratingsPageList = await this.getUserRatingsPageList({
                userId: user?.id,
                filter: currentFilters
            }).catch(() => { }).finally(() => {
                this.hasLoaded = !this.hasLoaded;
            });
            this.ratings = ratingsPageList.data;
            const { totalCount, totalPages } = ratingsPageList;
            this.pageListMetadata = { ...this.pageListMetadata, totalCount, totalPages };
            this.hasReviews = totalCount;
        },
    }
});
