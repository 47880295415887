<template>
    <el-card>
        <div class="card-review-container">
            <div class="column-one">
                <UserThumbnail
                borderRadius="50px"
                width="50px"
                height="50px"
                :key="profileImage"
                :source="profileImage"
                >
                </UserThumbnail>
                <div class="name-container">
                    <p class="name">{{ reviewerName}}</p>
                    <p class="type">Homeowner</p>
                </div>
            </div>
            <div class="column-two">
                <div class="rating-container">
                   <div class="rating">
                    <el-rate
                        :max="5"
                        :show-text="false"
                        :show-score="false"
                        v-model="currentRatingRecord.value"
                        disabled>
                    </el-rate>

                   </div>
                   <p class="day-posted">{{ fromNow }}</p>
                </div>
                <p class="comment" v-html="currentRatingRecord.comments"></p>
                <div class="mini-gallery-conatainer" v-if="attachments?.length">
                    <div class="image-item" v-for="(attachment, index) in attachments" :key="index">
                        <el-image
                            style="width: 97px; height: 67px"
                            :src="attachment"
                            fit="cover"
                            :preview-src-list="attachments"
                        >
                        </el-image>
                    </div>
                </div>
                <!-- <div class="comment-input-container">
                   <el-input placeholder="Reply" type="textarea" :maxlength="240" v-model="inputComment" clearable autosize></el-input>
                   <div class="comment-cta-container">
                       <inline-svg class="cta-icon" :src="require(`@/assets/icons/file-upload-icon.svg`)"></inline-svg>
                       <inline-svg class="cta-icon" :src="require(`@/assets/icons/chat-orange-send-icon.svg`)"></inline-svg>
                   </div>
                </div> -->
            </div>
        </div>
    </el-card>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_RATING_REVIEWS_CACHE } from '@/store/modules/attachment-cache/constants';

export default {
  components: {
    UserThumbnail,
  },

  props: ['rating'],

  data() {
    return {
      attachments: [],
    };
  },

  created() {
    this.loadAttachments();
  },

  computed: {
    currentRatingRecord() {
      return this.rating;
    },

    fromNow() {
      return moment(this.rating.dateCreated).from(new Date());
    },

    profileImage() {
      const { currentRatingRecord } = this;
      const { fromUser } = currentRatingRecord;

      return fromUser?.userPublicProfile?.profileImage || null;
    },

    reviewerName() {
      const { currentRatingRecord } = this;
      const { fromUser } = currentRatingRecord;

      return `${fromUser?.firstName} ${fromUser?.lastName}`;
    }
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    loadAttachments() {
      const { ratingAttachments } = this.currentRatingRecord;

      this.getAttachments({
        name: USER_RATING_REVIEWS_CACHE,
        attachments: ratingAttachments
      })
        .then((values) => {
          const newValues = values.filter(Boolean);

          if (newValues.length) {
            this.attachments = values.map((value) => value.src);
          }
        }).catch(() => {});
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

 .el-card.is-always-shadow {
     box-shadow: none;
 }
 .el-card {
    border-radius: 16px;
    border: none;

    .card-review-container {
        display: flex;
        // justify-content: space-between;
        gap: 4rem;

        .column-one {
            display:flex;
            align-items:center;
            justify-content: center;
            gap: 1rem;
            height: 100%;

            .name-container {
                display:flex;
                flex-direction: column;
                align-items:flex-start;
                justify-content: center;
                gap: .2rem;

                p {
                    margin: 0;
                }

                .name {
                    font-family: Mulish;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: #0C0F4A;
                }
                .type {
                    font-family: Mulish;
                    font-size: 12px;
                    font-weight: 600;
                    // line-height: 16px;
                    letter-spacing: 0.5px;
                    text-align: left;
                    color: rgba(12, 15, 74, 0.5);
                }
            }
        }

        .column-two {
            display:flex;
            flex-direction: column;
            align-items:flex-start;
            justify-content: center;
            gap: 1rem;

            .rating-container {
                width: 100%;
                display: flex;
                justify-content:space-between;
                .rating {
                    display:flex;
                    gap: .5rem;
                    align-items:center;
                }
                .day-posted {
                    position: absolute;
                    right: 20px;
                    margin: 0;
                    font-family: Mulish;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 0.5px;
                    text-align: right;
                    color: rgba(12, 15, 74, 0.5);
                }
            }

            .comment {
                margin: 0;
                font-family: Mulish;
                font-size: 12px;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #0C0F4A;
            }

            .mini-gallery-conatainer {
                display:flex;
                gap: 1rem;
                width: 100%;

                .image-item {
                    border-radius: 8px;
                    min-width: 116px;
                    min-height: 79.73px;
                    display:flex;
                    align-items:center;
                    justify-content: center;

                    img {
                        width: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }
            }

            .comment-input-container {
                width: 100%;
                display:flex;
                flex-direction: column;
                padding: 1rem 0;
                gap: .5rem;
                box-sizing: border-box;
                border-radius: 8px;
                background-color: rgba(12, 15, 74, 0.05);
                margin-top: 1rem;

                :deep(.el-textarea__inner) {
                outline: none;
                border: none;
                background: transparent;
                // padding: 5px 0;
                }

                .comment-cta-container {
                    display:flex;
                    gap: 1rem;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 0 1rem;
                }
            }
        }
    }
 }

 @include media-xs-max-width() {
    .el-card {
        .card-review-container {
            flex-direction: column;
            gap: 1rem;
            align-items: flex-start;
        }
    }
 }
</style>
